// @file Helper functions for dashboard.

import { galleryTextArray } from '@@/bits/collections_helper'
import type { UrlOptions } from '@@/bits/location'
import { getPathnameItems, getSearchParams, transformCurrentUrl } from '@@/bits/location'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { LibraryType } from '@@/enums'
import type { WallViz } from '@@/types'
import type { WallsFilter } from '@@/types/collections'
import { CollectionKeyTypes } from '@@/types/collections'
import type { DashView } from '@@/types/dash'
import { MobilePage } from '@@/types/dash'

export function dashViewUrlParams(dashView: DashView): Record<string, string> {
  const { collectionKey, filter, gallery, mobilePage, page } = dashView
  // The empty strings indicate that we want to remove those fields.
  const params: Record<string, string> = {
    page: page ?? '',
    mobile_page: mobilePage ?? '',
    filter: filter ?? '',
    gallery: gallery ?? '',
    folder_id: '',
    library_id: '',
  }

  if (collectionKey !== undefined && collectionKey !== null) {
    const { typeKey, indexKey } = collectionKey
    if (typeKey === CollectionKeyTypes.FolderId) {
      params.folder_id = String(indexKey)
    } else if (typeKey === CollectionKeyTypes.GroupFolderId) {
      params.group_id = String(indexKey)
    } else if (typeKey === CollectionKeyTypes.Filter) {
      params.filter = indexKey
    }
  }

  if (filter !== undefined) {
    params.filter = filter
  }

  if (gallery !== undefined) {
    params.gallery = gallery
  }

  return params
}

export function dashViewUrl(dashView: DashView, urlOptions?: UrlOptions): string {
  const searchParams = dashViewUrlParams(dashView)
  if (urlOptions?.searchParams != null) {
    Object.assign(searchParams, urlOptions.searchParams)
  }
  return transformCurrentUrl({ withQueryString: false }, { searchParams, path: urlOptions?.path ?? '/dashboard' })
}

const CrossLibraryToFilterMap = {
  recents: 'combined_recents',
  bookmarks: 'favorites',
  shared: 'combined_shared',
}

const TextToVizMap = {
  all: 'all',
  wall: 'grid',
  stream: 'stream',
  grid: 'matrix',
  shelf: 'shelf',
  map: 'map',
  canvas: 'free',
  timeline: 'timeline',
}

export function getDashPathnameParams(): Record<string, string> {
  const pathnameItems = getPathnameItems()
  if (pathnameItems[0] !== 'dashboard') {
    return {}
  }

  const crossLibraryArray = ['bookmarks', 'recents', 'shared']
  // path is /dashboard/:crossLibraryCollection
  if (crossLibraryArray.includes(pathnameItems[1])) {
    return { filter: CrossLibraryToFilterMap[pathnameItems[1]] as WallsFilter }
  }

  // path is /dashboard/gallery/:viz
  if (pathnameItems[1] === 'gallery') {
    if (galleryTextArray.includes(pathnameItems[2]) && pathnameItems[2] !== undefined) {
      return { gallery: TextToVizMap[pathnameItems[2]] }
    }

    // fallback to all if viz is incorrectly typed or not provided
    return { gallery: 'all' }
  }

  // path is /dashboard/make
  if (pathnameItems[1] === 'make') {
    return { make: 'make' }
  }

  // path is /dashboard/learn
  if (pathnameItems[1] === 'learn') {
    return { learn: 'learn' }
  }

  // path is /dashboard/<LibraryType>/:librarySlug(/:tab)
  if (Object.values(LibraryType).includes(pathnameItems[1] as LibraryType) && pathnameItems[2] !== undefined) {
    if (pathnameItems[3] === 'gallery') return { librarySlug: pathnameItems[2], gallery: 'all' }
    if (pathnameItems[3] === 'learn') return { librarySlug: pathnameItems[2], learn: 'learn' }
    if (pathnameItems[3] === 'make') return { librarySlug: pathnameItems[2], make: 'make' }
    return { librarySlug: pathnameItems[2] }
  }

  return {}
}

export function getDashViewFromUrlParams(urlParams: Record<string, string> | null = null): DashView {
  const searchParams = urlParams ?? getSearchParams()
  const pathnameParams = getDashPathnameParams()
  const librarySlug = pathnameParams.librarySlug
  const folderId = searchParams.folder_id
  const groupId = searchParams.group_id
  const filter = pathnameParams.filter ?? searchParams.filter
  const gallery = pathnameParams.gallery ?? searchParams.gallery
  const learn = pathnameParams.learn ?? searchParams.learn
  const make = pathnameParams.make ?? searchParams.make
  const mobilePage = searchParams.mobile_page
  const page = searchParams.page
  const create = searchParams.create

  const dashView: DashView = {}

  if (page !== undefined && page !== '') {
    dashView.page = page
  } else if (create !== undefined && create !== '') {
    dashView.page = 'layoutPicker'
  }

  if (mobilePage !== undefined && mobilePage !== '') {
    dashView.mobilePage = mobilePage as MobilePage
  }

  if (librarySlug !== undefined && librarySlug !== '') {
    dashView.librarySlug = librarySlug
    dashView.mobilePage = MobilePage.CollectionsMenu

    // If the librarySlug and filter is exist, the mobilePage should be collection
    if (filter !== undefined && filter !== '') {
      dashView.mobilePage = MobilePage.Collection
    }
  }

  if (folderId !== undefined && folderId !== '') {
    dashView.collectionKey = { typeKey: CollectionKeyTypes.FolderId, indexKey: Number(folderId) }
  } else if (groupId !== undefined && groupId !== '') {
    dashView.collectionKey = { typeKey: CollectionKeyTypes.GroupFolderId, indexKey: Number(groupId) }
  } else if (filter !== undefined && filter !== '') {
    dashView.filter = filter as WallsFilter
  }

  if (gallery !== undefined && gallery !== '') {
    dashView.gallery = gallery as WallViz
  }

  if (make !== undefined && make !== '') {
    dashView.make = make as 'make'
  }

  if (learn !== undefined && learn !== '') {
    dashView.learn = learn as 'learn'
  }

  // If a cross library filter exist, a mobile_page must be set
  // else the user will not be able to view the page in mobile directly
  if (dashView.mobilePage === undefined && dashView.filter !== undefined) {
    switch (dashView.filter) {
      case CrossLibraryToFilterMap.bookmarks:
        dashView.mobilePage = MobilePage.CollectionsMenu
        break
      case CrossLibraryToFilterMap.recents:
      case CrossLibraryToFilterMap.shared:
        dashView.mobilePage = MobilePage.Collection
        break
    }
  }

  // If a gallery param exist, a mobile_page must be set
  // else the user will not be able to view the page in mobile directly
  if (dashView.mobilePage === undefined && dashView.gallery !== undefined) {
    switch (dashView.gallery) {
      case 'all': // Special case for 'all' since it is used for the collections menu page
        dashView.mobilePage = MobilePage.CollectionsMenu
        break
      default:
        dashView.mobilePage = MobilePage.Collection
        break
    }
  }
  return dashView
}

export function saveDashViewUrlIntoStorage(): void {
  safeLocalStorage.setItem('dashViewUrl', window.location.href)
}

export function getDashViewUrlFromStorage(): string | null {
  return safeLocalStorage.getItem('dashViewUrl')
}

export function clearDashViewUrlFromStorage(): void {
  safeLocalStorage.removeItem('dashViewUrl')
}
